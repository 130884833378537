import React from "react"
import { Form } from "react-bootstrap"
import { filesallow } from "../../page/Clients/constants"
import eyeIcon from "./../../assets/images/eye-icon.svg"
import eyeOff from "./../../assets/images/eye-off.svg"
import fileIcon from "./../../assets/images/fileicon.svg"
import pdfIcon from "./../../assets/images/pdf-icon.png"
import docIcon from "./../../assets/images/doc-icon.png"
import xlsIcon from "./../../assets/images/xls-icon.png"
import txtIcon from "./../../assets/images/txt-icon.png"
import deleteIcon from "./../../assets/images/cross-white.svg"
const CommonTextInput = React.forwardRef((props, ref) => {
  const {
    logoDelete = () => {},
    label = true,
    type,
    name,
    placeholder,
    file = "false",
    textarea = "false",
    rows = 4,
    cols = 7,
    loginError,
    setIsDisabled,
    path = "",
    accept = "",
    filesallow = "",
    showPassword = false,
    id = false,
    handleshowPassword = false,
    removebutton = true
  } = props

  if (props.textarea === "true") {
    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          as="textarea"
          ref={ref}
          rows={rows}
          cols={cols}
          className={`form-control h-auto ${loginError?.[name] && "is-invalid"}`}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else if (props.file == "true") {
    // console.log("🚀 ~ CommonTextInput ~ path:", path)
    return (
      <>
        <Form.Label>{label}</Form.Label>
        <br />
        {path ? (
          <div className="image-upload position-relative">
            <a href={path} target="_blank">
              <img
                src={path}
                alt="Logo"
                style={{
                  height: "120px",
                  width: "120px"
                }}
                className="d-block mb-20 form-logo"
              />
            </a>
            {removebutton && (
              <button type="button" onClick={logoDelete}>
                <img src={deleteIcon} alt="" />
              </button>
            )}
          </div>
        ) : null}
        <div className="input-group custom-file-button">
          <Form.Label className="input-group-text mb-0" htmlFor="inputGroupFile">
            {placeholder}
          </Form.Label>
          <Form.Control
            ref={ref}
            type={type}
            accept={accept}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p>{filesallow}</p>
        {loginError && loginError?.[name] && (
          <div
            className="invalid-feedback fs-4"
            style={{
              display: "block"
            }}
          >
            {loginError?.[name]?.message}
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}

        {showPassword ? (
          <div className="pwd-form">
            <Form.Control
              ref={ref}
              type={type}
              className={`form-control ${loginError?.[name] && "is-invalid"} ${type == "text" ? 'text-input' : ""}`}
              placeholder={placeholder}
              disabled={setIsDisabled}
              {...props}
              min="0"
            />
            <img
              src={type === "text" ? eyeIcon : eyeOff}
              alt="eye"
              className="eye-icon cursor-pointer"
              onClick={() => handleshowPassword(id)}
            />
          </div>
        ) : (
          <Form.Control
            ref={ref}
            type={type}
            className={`form-control ${loginError?.[name] && "is-invalid"}`}
            placeholder={placeholder}
            disabled={setIsDisabled}
            {...props}
            min="0"
          />
        )}

        {loginError?.[name] && (
          <div className="invalid-feedback fs-4">{loginError?.[name]?.message}</div>
        )}
      </>
    )
  }
})

export default CommonTextInput
export const CommonTextInputId = React.forwardRef((props, ref) => {
  const {
    logoDelete = () => {},
    label = true,
    type,
    name,
    placeholder,
    file = "false",
    textarea = "false",
    rows = 4,
    cols = 7,
    loginError,
    setIsDisabled,
    path = "",
    accept = "",
    filesallow = "",
    showPassword = false,
    id = false,
    handleshowPassword = false
  } = props

  if (props.textarea === "true") {
    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          as="textarea"
          ref={ref}
          rows={rows}
          cols={cols}
          className={`form-control h-auto ${loginError?.[name] && "is-invalid"}`}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else if (props.file == "true") {
    return (
      <>
        <Form.Label>{label}</Form.Label>
        <br />
        {path ? (
          <a href={path} target="_blank">
            <div className="image-upload position-relative">
              <img
                src={path}
                alt="Logo"
                style={{
                  height: "120px",
                  width: "120px"
                }}
                className="d-block mb-20 form-logo"
              />
              <button type="button" onClick={logoDelete}>
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          </a>
        ) : null}
        <div className="input-group custom-file-button">
          <Form.Label className="input-group-text mb-0" htmlFor="inputGroupFileId">
            {placeholder}
          </Form.Label>
          <Form.Control
            ref={ref}
            type={type}
            accept={accept}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p>{filesallow}</p>
        {loginError && loginError?.[name] && (
          <div
            className="invalid-feedback fs-4"
            style={{
              display: "block"
            }}
          >
            {loginError?.[name]?.message}
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}

        {showPassword ? (
          <div className="pwd-form">
            <Form.Control
              ref={ref}
              type={type}
              className={`form-control ${loginError?.[name] && "is-invalid"}`}
              placeholder={placeholder}
              disabled={setIsDisabled}
              {...props}
              min="0"
            />
            <img
              src={type === "text" ? eyeOff : eyeIcon}
              alt="eye"
              className="eye-icon cursor-pointer"
              onClick={() => handleshowPassword(id)}
            />
          </div>
        ) : (
          <Form.Control
            ref={ref}
            type={type}
            className={`form-control ${loginError?.[name] && "is-invalid"}`}
            placeholder={placeholder}
            disabled={setIsDisabled}
            {...props}
            min="0"
          />
        )}

        {loginError?.[name] && (
          <div className="invalid-feedback fs-4">{loginError?.[name]?.message}</div>
        )}
      </>
    )
  }
})
// export const CommonTextInputPrev = React.forwardRef((props, ref) => {
//   const {
//     logoDelete = () => {},
//     label = true,
//     type,
//     name,
//     placeholder,
//     file = "false",
//     loginError,
//     setIsDisabled,
//     path = "",
//     accept = "",
//     filesallow = "",
//     id = false
//   } = props;

//   console.log("🚀 ~ CommonTextInputPrev ~ path:", path);

//   const handleFileOrBlob = () => {
//     // Check if path is a valid file type
//     if (typeof path === "string") {
//       // If the path is a string, return it as is
//       return path;
//     } else {
//       // Otherwise, create a Blob from the file-like object
//       const fileBlob = new Blob([path], { type: "application/octet-stream" });
//       return URL.createObjectURL(fileBlob); // Convert blob to URL for display
//     }
//   };


//   const processedPath = handleFileOrBlob(); // Process the path
//   console.log("🚀 ~ CommonTextInputPrev ~ processedPath:", processedPath)

//   if (props.file === "true") {
//     console.log(processedPath, 'processedPath');
    
//     return (
//       <>
//         <Form.Label>{label}</Form.Label>
//         <br />
//         <>
//           {processedPath && processedPath.length > 0 ? (
//             <div className="image-upload position-relative">
//               {processedPath.toLowerCase().endsWith(".jpg") ||
//               processedPath.toLowerCase().endsWith(".jpeg") ||
//               processedPath.toLowerCase().endsWith(".png") ? (
//                 <>
//                   <a href={processedPath} target="_blank" rel="noopener noreferrer">
//                     <img
//                       src={processedPath}
//                       alt="Logo"
//                       style={{
//                         height: "120px",
//                         width: "120px"
//                       }}
//                       className="d-block mb-20 form-logo"
//                     />
//                   </a>
//                 </>
//               ) : (
//                 <div className="file-thumbnail test">
//                   <>
//                     <a href={processedPath} target="_blank" rel="noopener noreferrer">
//                       <img
//                         src={
//                           processedPath.toLowerCase().endsWith(".pdf")
//                             ? pdfIcon
//                             : processedPath.toLowerCase().endsWith(".docx")
//                             ? docIcon
//                             : processedPath.toLowerCase().endsWith(".doc")
//                             ? docIcon
//                             : processedPath.toLowerCase().endsWith(".txt")
//                             ? txtIcon
//                             : processedPath.toLowerCase().endsWith(".xls")
//                             ? xlsIcon
//                             : processedPath.toLowerCase().endsWith(".xlsx")
//                             ? xlsIcon
//                             : processedPath.toLowerCase().endsWith(".csv")
//                             ? xlsIcon
//                             : fileIcon
//                         }
//                         alt="FILE"
//                       />
//                     </a>
//                   </>
//                 </div>
//               )}
//               <button type="button" onClick={logoDelete}>
//                 <img src={deleteIcon} alt="" />
//               </button>
//             </div>
//           ) : null}
//         </>
//         <div className="input-group custom-file-button">
//           <Form.Label className="input-group-text mb-0" htmlFor={id}>
//             {placeholder}
//           </Form.Label>
//           <Form.Control
//             ref={ref}
//             type={type}
//             accept={accept}
//             placeholder={placeholder}
//             {...props}
//           />
//         </div>
//         <p>{filesallow}</p>
//         {loginError && loginError?.[name] && (
//           <div
//             className="invalid-feedback fs-4"
//             style={{
//               display: "block"
//             }}
//           >
//             {loginError?.[name]?.message}
//           </div>
//         )}
//       </>
//     );
//   }
// });

export const CommonTextInputPrev = React.forwardRef((props, ref) => {
  const {
    logoDelete = () => {},
    label = true,
    type,
    name,
    placeholder,
    file = "false",
    loginError,
    setIsDisabled,
    path = "",
    accept = "",
    filesallow = "",
    id = false
  } = props
  // console.log("🚀 ~ CommonTextInputPrev ~ path:", path)

  if (props.file === "true") {
    // console.log(path,'path');

    return (
      <>
        <Form.Label>{label}</Form.Label>
        <br />
        <>
          {path && path?.length > 0 ? (
            <div className="image-upload position-relative">
              {path?.toLowerCase()?.endsWith(".jpg") ||
              path?.toLowerCase()?.endsWith(".jpeg") ||
              path?.toLowerCase()?.endsWith(".png") ? (
                <>
                  <a href={path} target="_blank">
                    <img
                      src={path}
                      alt="Logo"
                      style={{
                        height: "120px",
                        width: "120px"
                      }}
                      className="d-block mb-20 form-logo"
                    />
                  </a>
                </>
              ) : (
                <div className="file-thumbnail test">
                  <>
                    <a href={path} target="_blank">
                      <img
                        src={
                          path?.toLowerCase().endsWith(".pdf")
                            ? pdfIcon
                            : path?.toLowerCase().endsWith(".docx")
                            ? docIcon
                            : path?.toLowerCase().endsWith(".doc")
                            ? docIcon
                            : path?.toLowerCase().endsWith(".txt")
                            ? txtIcon
                            : path?.toLowerCase().endsWith(".xls")
                            ? xlsIcon
                            : path?.toLowerCase().endsWith(".xlsx")
                            ? xlsIcon
                            : path?.toLowerCase().endsWith(".csv")
                            ? xlsIcon
                            : fileIcon
                        }
                        alt="FILE"
                      />
                    </a>
                  </>
                </div>
              )}
              <button type="button" onClick={logoDelete}>
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          ) : null}
        </>
        <div className="input-group custom-file-button">
          <Form.Label className="input-group-text mb-0" htmlFor={id}>
            {placeholder}
          </Form.Label>
          <Form.Control
            ref={ref}
            type={type}
            accept={accept}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p>{filesallow}</p>
        {loginError && loginError?.[name] && (
          <div
            className="invalid-feedback fs-4"
            style={{
              display: "block"
            }}
          >
            {loginError?.[name]?.message}
          </div>
        )}
      </>
    )
  }
})
export const CommonTextInputInstru = React.forwardRef((props, ref) => {
  const {
    logoDelete = () => {},
    label = true,
    type,
    name,
    placeholder,
    file = "false",
    loginError,
    setIsDisabled,
    path = "",
    accept = "",
    filesallow = "",
    id = false
  } = props
  console.log("🚀 ~ CommonTextInputPrev ~ path:", path)

  if (props.file === "true") {
    console.log(path,'path');

    return (
      <>
        <Form.Label>{label}</Form.Label>
        <br />
        {
          typeof path == "string" && (

        <>
          {path ? (
            <div className="image-upload position-relative">
              {path?.toLowerCase()?.endsWith(".jpg") ||
              path?.toLowerCase()?.endsWith(".jpeg") ||
              path?.toLowerCase()?.endsWith(".png") ? (
                <>
                  <a href={path} target="_blank">
                    <img
                      src={path}
                      alt="Logo"
                      style={{
                        height: "120px",
                        width: "120px"
                      }}
                      className="d-block mb-20 form-logo"
                    />
                  </a>
                </>
              ) : (
                <div className="file-thumbnail test">
                  <>
                    <a href={path} target="_blank">
                      <img
                        src={
                          path?.toLowerCase().endsWith(".pdf")
                            ? pdfIcon
                            : path?.toLowerCase().endsWith(".docx")
                            ? docIcon
                            : path?.toLowerCase().endsWith(".doc")
                            ? docIcon
                            : path?.toLowerCase().endsWith(".txt")
                            ? txtIcon
                            : path?.toLowerCase().endsWith(".xls")
                            ? xlsIcon
                            : path?.toLowerCase().endsWith(".xlsx")
                            ? xlsIcon
                            : path?.toLowerCase().endsWith(".csv")
                            ? xlsIcon
                            : fileIcon
                        }
                        alt="FILE"
                      />
                    </a>
                  </>
                </div>
              )}
              <button type="button" onClick={logoDelete}>
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          ) : null}
        </>
          )
        }
        <div className="input-group custom-file-button">
          <Form.Label className="input-group-text mb-0" htmlFor={id}>
            {placeholder}
          </Form.Label>
          <Form.Control
            ref={ref}
            type={type}
            accept={accept}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p>{filesallow}</p>
        {loginError && loginError?.[name] && (
          <div
            className="invalid-feedback fs-4"
            style={{
              display: "block"
            }}
          >
            {loginError?.[name]?.message}
          </div>
        )}
      </>
    )
  }
})

export const CommonTextInputExport = React.forwardRef((props, ref) => {
  const {
    actualPath,
    logoDelete = () => {},
    label = true,
    type,
    name,
    placeholder,
    file = "false",
    loginError,
    setIsDisabled,
    path = "",
    accept = "",
    filesallow = "",
    id = false
  } = props

  if (props.file === "true") {
    return (
      <>
        <Form.Label>{label}</Form.Label>
        <br />
        <>
          {actualPath ? (
            <div className="image-upload position-relative">
              {actualPath?.toLowerCase().endsWith(".jpg") ||
              actualPath?.toLowerCase().endsWith(".jpeg") ||
              actualPath?.toLowerCase().endsWith(".png") ? (
                <>
                  <a href={actualPath} target="_blank">
                    <img
                      src={path}
                      alt="Logo"
                      style={{
                        height: "120px",
                        width: "120px"
                      }}
                      className="d-block mb-20 form-logo"
                    />
                  </a>
                </>
              ) : (
                <div className="file-thumbnail">
                  {actualPath.slice(-4).toLowerCase() === ".pdf" ? (
                    <>
                      <a href={actualPath} target="_blank">
                        <img src={fileIcon} alt="FILE" />
                      </a>
                    </>
                  ) : (
                    <a href={actualPath} target="_blank">
                      <img src={fileIcon} alt="FILE" />
                    </a>
                  )}
                </div>
              )}
              <button type="button" onClick={logoDelete} className="remove-item">
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          ) : null}
        </>
        <div className="input-group custom-file-button">
          <Form.Label className="input-group-text mb-0" htmlFor="inputGroupFile">
            {placeholder}
          </Form.Label>
          <Form.Control
            ref={ref}
            type={type}
            accept={accept}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p>{filesallow}</p>
        {loginError && loginError?.[name] && (
          <div
            className="invalid-feedback fs-4"
            style={{
              display: "block"
            }}
          >
            {loginError?.[name]?.message}
          </div>
        )}
      </>
    )
  }
})
