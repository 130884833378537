import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Loader from "../components/Loader";
import Toaster from "../components/Toaster/Toaster";
import * as AuthApi from "../api/AuthApi";
import * as SuperAdminPermission from "../page/SUPERADMIN/api/authApi";
import InjectProtectedNode from "./injectProtectedNode";
import UnauthorizerNode from "./unauthorizerNode";
import {
  resetAuthData,
  addAuthData,
} from "../store/slices/authUser/authUserSlice";
import { showUpdatedToasterMessage } from "../store/slices/toaster/toasterslice";

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authSelector = useSelector((state) => state.ars.authUserReducer);
  const [authData, setAuthData] = useState(authSelector);
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_URL;
      if (authSelector?.access_token) {
        req.headers = {
          "Content-Type": "application/json",

          Authorization: `Bearer ${authSelector.access_token}`,
          accesscode: authSelector.accesscode,
          ...req.headers,
        };
      }
      return req;
    },
    (error) => {
      return error;
    }
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //catches if the session ended!
      if (error.response.status === 401) {
        dispatch(
          showUpdatedToasterMessage({
            message: error.response.statusText,
            type: "danger",
          })
        );
        dispatch(resetAuthData());
        authSelector?.user?.user_type === "S" ?  navigate("/login/sa"):
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );

  const updateuserPermission = async () => {
    try {
      const res = await AuthApi.updatePermission();
      console.log("🚀 ~ updateuserPermission ~ res:", res);
      if (res.status == true) {
        const newData = {
        
          ...authSelector,
          user_permissions: [...res.data.user_permissions],
        };
        console.log("🚀 ~ updateuserPermission ~ newData:", newData)
        dispatch(addAuthData(newData));
      }
    } catch (e) {}
  };
  const updateSuperAdminPermission = async () => {
    
    try {
      const res = await SuperAdminPermission.updatePermissionSa();
      if (res.status == true) {
        const newData = {
          ...authSelector,
          user_permissions: [...res.data.user_permissions],
        };
        dispatch(addAuthData(newData));
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (authSelector?.access_token) {
      if (authSelector?.user?.user_type === "AA") {
        updateuserPermission();
      } else if (authSelector?.user?.user_type === "SU") {
        updateSuperAdminPermission();
      }
    }
  }, [location]);

  return (
    <Fragment>
      {authSelector.auth ? (
        <InjectProtectedNode authSelector={authSelector} />
      ) : (
        <Suspense fallback={<Loader />}>
          <UnauthorizerNode />
        </Suspense>
      )}
      <Toaster />
    </Fragment>
  );
};

export default Layout;
