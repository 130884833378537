import React, { useState, Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import useLogin from "../hooks/useLogin";
import SiteHeader from "../interface/Header";
import * as AuthApi from "../api/AuthApi"
import { resetAuthData,addAuthData } from "../store/slices/authUser/authUserSlice";
const InjectProtectedNode = ({ authSelector }) => {
  //const authSelector = useSelector((state) => state.ars.authUserReducer);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);;
  const toggleActive = () => {
    setIsActive((active) => {
      return !active;
    });
  };

  const getRoles = useLogin(authSelector, isActive);
console.log('InjectProtectedNode');
// const updateuserPermission = async()=>{
//   try{
//   const res = await AuthApi.updatePermission()
//   console.log("🚀 ~ updateuserPermission ~ res:", res)
//   if(res.status == true){
  
//     const newData = {...authSelector,user_permissions:[...res.data.user_permissions]}
//     dispatch(addAuthData(newData))
//     }
//   }
//   catch(e){
  
//   }
//   }
//   useEffect(() => {
//    if(authSelector){
//   updateuserPermission()
//    }
//   }, [])
  
  return (
    <SiteHeader isActive={isActive} toggleActive={toggleActive}>
      <Suspense fallback={<Loader top="64px" height="calc(100% - 64px)" />}>
        {useRoutes(getRoles)}
      </Suspense>
    </SiteHeader>
  );
};

export default InjectProtectedNode;
