import {
  IconBrandAirtable,
  IconFolder,
  IconHome2,
  IconLayersSubtract,
  IconListDetails,
  IconLogout,
  IconSettings,
  IconUser,
  IconUserPlus,
  IconUsers,
} from "@tabler/icons-react";
import Chains from "../assets/images/nodatasvg/Chains.svg";
import Projects from "../assets/images/nodatasvg/Projects.svg";
import Rounds from "../assets/images/nodatasvg/Rounds.svg";
import Surveys from "../assets/images/nodatasvg/Survey.svg";
import SurveysTemp from "../assets/images/nodatasvg/Survey Templates.svg";
import Clients from "../assets/images/nodatasvg/Clients.svg";
import Sites from "../assets/images/nodatasvg/Sites.svg";
import Fieldreps from "../assets/images/nodatasvg/Field Reps.svg";
import Notifications from "../assets/images/nodatasvg/Notifications (1).svg";
import Broadcast from "../assets/images/nodatasvg/broadcast-message-icon.svg";
import ReportIsssue from "../assets/images/nodatasvg/Report an Issue.svg";
import Users from "../assets/images/nodatasvg/Users (1).svg";
import JobPost from "../assets/images/nodatasvg/Job Post.svg";
import { useSelector } from "react-redux";
export const NAV_ITEMS = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: <IconHome2 size={20} stroke={1.2} className="nav-icon me-2" />,
  },
  {
    name: "Users",
    to: "/users",
    icon: <IconUsers size={20} stroke={1.2} className="nav-icon me-2" />,
  },
  {
    name: "Categories",
    to: "/categories",
    icon: <IconListDetails size={20} stroke={1.2} className="nav-icon me-2" />,
  },
  {
    name: "Materials",
    to: "/materials",
    icon: (
      <IconLayersSubtract size={20} stroke={1.2} className="nav-icon me-2" />
    ),
  },
  {
    name: "Models",
    to: "/models",
    icon: (
      <IconBrandAirtable size={20} stroke={1.2} className="nav-icon me-2" />
    ),
  },
  {
    name: "Projects",
    to: "/projects",
    icon: <IconFolder size={20} stroke={1.2} className="nav-icon me-2" />,
  },
  {
    name: "Role & Permission",
    to: "/roles",
    icon: <IconUserPlus size={20} stroke={1.2} className="nav-icon me-2" />,
  },
];

export const ACCOUNT_DROPDOWN_ITEMS = [
  {
    icon: <IconUser size={20} stroke={1.2} className="nav-icon me-2" />,
    value: "My Profile",
    to: "",
  },
  {
    icon: <IconSettings size={20} stroke={1.2} className="nav-icon me-2" />,
    value: "Settings",
    to: "/settings/my-account",
  },
  {
    icon: <IconLogout size={20} stroke={1.2} className="nav-icon me-2" />,
    value: "Logout",
    to: "/logout",
  },
];

export const PAGE_LENGTH = [10, 25, 50, 100];

export const ROLES = [
  {
    roleName: "ADMIN",
  },
  {
    roleName: "USER",
  },
];

export const ROLE_TYPES = {
  read: false,
  write: false,
  update: false,
  delete: false,
};
export const ROLE_TYPES_TRUE = {
  read: true,
  write: true,
  update: true,
  delete: true,
};

export const ROLE_MODULES = [
  {
    name: "Dashboard",
    ...ROLE_TYPES,
  },
  {
    name: "Users",
    ...ROLE_TYPES,
  },
  {
    name: "Categories",
    ...ROLE_TYPES,
  },
  {
    name: "Materials",
    ...ROLE_TYPES,
  },
  {
    name: "Models",
    ...ROLE_TYPES,
  },
  {
    name: "Projects",
    ...ROLE_TYPES,
  },
  {
    name: "Role & Permission",
    ...ROLE_TYPES,
  },
  {
    name: "My Profile",
    read: true,
    write: true,
    update: true,
    delete: true,
  },
  {
    name: "Settings",
    read: true,
    write: true,
    update: true,
    delete: true,
  },
];
export const DEFAULT_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you really want to delete this data? what you've done can't be undone.",
  actionBtnText: "Delete",
  action: "Delete",
};


export const INSTRUCTION_DELETE_MODAL = {
  show: false,
  isLoading: false,
  title: "Delete Assigned Surveys",
  description: "Are you sure you want to delete assigned surveys",
  actionBtnText: "Delete",
  action: "Delete",
};

export const UNASSIGNED_FIELDREP = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description: "Do you really want to unassigned this survey?",
  actionBtnText: "Unassigned",
  action: "Unassigned",
};


export const FORM_CONSTANTS = {
  EMAIL_TEMPLATE: {
    category: {
      name: "category",
      label: "Category*",
      placeholder: "ARS user has cancelled subscription"
    },
    name: {
      name: "name",
      label: "Name*",
      placeholder: "Enter name"
    },
    subject: {
      name: "subject",
      label: "Subject*",
      placeholder: "Enter subject"
    },
    from_name :{
      name:"from_name",
      label: "From Name*",
      placeholder: "Enter from name"
    },
    from_email :{
      name:"from_email",
      label: "From Email*",
      placeholder: "Enter from email"
    },
    to_email :{
      name:"to_email",
      label: "To Email*",
      placeholder: "Enter to email"
    },   
    
    emailTemplateBody: {
      name:"email_template_body",
      label: "Email Template Body"
    }
  }
}

export const CRUD_TYPES = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete"
}

export const DRAGGABLE_FORM_CONSTANTS = {
  HEADER: "header",
  TEXTBOX: "text",
  TEXTAREA:"textarea",
  CHECKBOX:"checkbox",
  CHECKBOXGRP:'Checkbox Group',
  SELECT:"select",
  RADIO:"radio",
  DATE:"date",
  FILE:"file",
}

const usePermission = [
  {
      "permission_id": 1,
      "entity_name": "Projects",
      "permission": [
          "view_project"
      ]
  },
  {
      "permission_id": 5,
      "entity_name": "Rounds",
      "permission": [
          "view_round",
          "edit_round"
      ]
  },
 
];

// export function isPermission(permissionType, userRole) {
// //   const parsedData = JSON?.parse(localStorage?.getItem('persist:root'));
// // const reducerdata = JSON?.parse(parsedData?.authUserReducer)
// const reducerdata = JSON?.parse(JSON?.parse(localStorage?.getItem('persist:root'))?.authUserReducer);
//   const permissionObj = reducerdata?.user_permissions?.find(permission => permission?.entity_name === permissionType);
//   if (permissionObj) {
//       if (permissionObj?.permission?.includes(userRole)) {
//           return true;
//       } else {
//           return false;
//       }
//   } else {
//       return false;
//   }
// }
export function isPermission(userRole) {
  const reducerdata = JSON?.parse(JSON?.parse(localStorage?.getItem('persist:root'))?.authUserReducer);
  const permissions = reducerdata?.user_permissions || [];
  for (const permission of permissions) {
      if (permission.permission?.includes(userRole)) {
          return true;
      }
  }
  return false;
}


export const NO_DATA = [
  { label: "No projects found", value: "projects",icon:Projects },
  { label: "No rounds found", value: "rounds",icon:Rounds },
  { label: "No surveys found", value: "surveys",icon:Surveys },
  { label: "No clients found", value: "clients" ,icon:Clients},
  { label: "No chains found", value: "chains",icon:Chains },
  { label: "No sites found", value: "sites",icon:Sites },
  { label: "No field reps found", value: "fieldreps",icon:Fieldreps },
  { label: "No survey templates found", value: "surveytemplates",icon:SurveysTemp },
  { label: "No users found", value: "users",icon:Users },
  { label: "No customers found", value: "customers",icon:Users },
  { label: "No jobpost found", value: "jobpost",icon:JobPost },
  { label: "No notifications found", value: "notifications",icon:Notifications },
  { label: "No report an issues found", value: "reportissue",icon:ReportIsssue },
  { label: "No broadcast messages found", value: "broadcast",icon:Broadcast },
];