import axios from "axios"

export const login = async (payload) => {
  return await axios
    .post("auth/login", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const forgotPassword = async (payload) => {
  return await axios
    .post("auth/forgot-password", payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const verify = async (token) => {
  return await axios
    .get("auth/verify-token/" + token)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const resetPassword = async (token, payload) => {
  return await axios
    .post("auth/reset-password/" + token, payload)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const logout = async (data, headers) => {
  return await axios
    .post("/auth/logout", data, headers)
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}
export const updatePermission = async () => {
  return await axios
    .post("roles/getAllPermissionsUser")
    .then((res) => {
      return res?.data
    })
    .catch((error) => Promise.reject(error?.response?.data))
}

export const checkPermission = async (data) => {
  const reducerdata = JSON?.parse(
    JSON?.parse(localStorage?.getItem("persist:root"))?.authUserReducer
  )
  console.log("🚀 ~ checkPermission ~ reducerdata:", reducerdata)
  if (reducerdata?.user?.user_type === "A") {
    return true
  }
  try {
    const response = await axios.get(`/roles/checkPermission/${data}`)
    return response?.data.status
  } catch (error) {
    console.error("Error occurred while checking permission:", error)
    return false
  }
}
