import React from "react";

const CheckBox = ({ name, value, checked, onChange, classname, disabled}) => {
  return (
    <div className={`${classname ? classname : "field-radio-block"}`}>
      <label className={`checkbox checkbox-outline-primary mb-0 ${disabled ? 'disabled' : ''}`}>{value}
        <input name={name} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBox;
