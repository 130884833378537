export const MENU_DATA = [
  {
    id: 1,
    MenuTitle: "Dashboard",
    MenuLink: "/"
  },
  {
    id: 2,
    MenuTitle: "Projects",
    MenuLink: "/projects"
  },
  {
    id: 3,
    MenuTitle: "Rounds",
    MenuLink: "/rounds"
  },
  {
    id: 4,
    MenuTitle: "Surveys",
    MenuLink: "/surveys"
  },
  {
    id: 5,
    MenuTitle: "Clients",
    MenuLink: "/clients"
  },
  {
    id: 6,
    MenuTitle: "Chains",
    MenuLink: "/chains"
  },
  {
    id: 7,
    MenuTitle: "Sites",
    MenuLink: "/sites"
  },
  {
    id: 8,
    MenuTitle: "Fieldreps",
    MenuLink: "/fieldreps"
  },
  {
    id: 9,
    MenuTitle: "Survey Templates",
    MenuLink: "/drafts"
  },
  {
    id: 10,
    MenuTitle: "Users",
    MenuLink: "/users"
  },
  {
    id: 11,
    MenuTitle: "Recruitment",
    MenuLink: "/recruitment"
  },
  {
    id: 12,
    MenuTitle: "Reports",
    MenuLink: "/reports"
  },
  {
    id: 13,
    MenuTitle: "Broadcast Messages",
    MenuLink: "/broadcastMessages"
  },
  {
    id: 14,
    MenuTitle: "Imports",
    MenuLink: "/imports"
  },
  {
    id: 15,
    MenuTitle: "Export Survey",
    MenuLink: "/exportSurvey"
  },
  {
    id: 16,
    MenuTitle: "Email Templates",
    MenuLink: "/emailTemplates"
  },
  {
    id: 17,
    MenuTitle: "Roles & Permissions",
    MenuLink: "/roles"
  },
  {
    id: 18,
    MenuTitle: "Report an Issue",
    MenuLink: "/reportIssue"
  },
  {
    id: 19,
    MenuTitle: "Settings",
    MenuLink: "/settings"
  },
  {
    id: 20,
    MenuTitle: "Help",
    MenuLink: "/help"
  }
]

export const FIELDREP_MENU_DATA = [
  {
    id: 1,
    MenuTitle: "Dashboard",
    MenuLink: "/fieldrep/dashboard"
  },
  {
    id: 2,
    MenuTitle: "Offers",
    MenuLink: "/fieldrep/offers"
  },
  {
    id: 3,
    MenuTitle: "Surveys",
    MenuLink: "/fieldrep/surveys"
  },
  {
    id: 4,
    MenuTitle: "Client Resources",
    MenuLink: "/fieldrep/resources"
  },

  {
    id: 5,
    MenuTitle: "Calendar",
    MenuLink: "/fieldrep/calendar"
  },
  {
    id: 6,
    MenuTitle: "Broadcast Messages",
    MenuLink: "/fieldrep/broadcastMessages"
  },
  {
    id: 7,
    MenuTitle: "Report an Issue",
    MenuLink: "/fieldrep/reportIssue"
  },
  {
    id: 8,
    MenuTitle: "Help",
    MenuLink: "/fieldrep/help"
  }
]

export const SUPERADMIN_MENU_DATA = [
  {
    id: 1,
    MenuTitle: "Dashboard",
    MenuLink: "/sa/dashboard"
  },
  {
    id: 2,
    MenuTitle: "Inquiries",
    MenuLink: "/sa/inquiries"
  },
  {
    id: 3,
    MenuTitle: "Customers",
    MenuLink: "/sa/customers"
  },
  {
    id: 4,
    MenuTitle: "Billing",
    MenuLink: "/sa/billing"
  },
  {
    id: 5,
    MenuTitle: "Users",
    MenuLink: "/sa/users"
  },
  {
    id: 6,
    MenuTitle: "Reports",
    MenuLink: "/sa/reports"
  },
  {
    id: 7,
    MenuTitle: "Broadcast Messages",
    MenuLink: "/sa/broadcastMessages"
  },
  {
    id: 8,
    MenuTitle: "Roles & Permissions",
    MenuLink: "/sa/roles"
  },
  {
    id: 9,
    MenuTitle: "Tickets (Report an Issue)",
    MenuLink: "/sa/tickets"
  },
  {
    id: 10,
    MenuTitle: "Settings",
    MenuLink: "/sa/settings"
  },
  {
    id: 11,
    MenuTitle: "Help",
    MenuLink: "/sa/help"
  }
]
export const MENU_DATA_USER_AA = [
  {
    id: 1,
    MenuTitle: "Dashboard",
    MenuLink: "/"
  },
  // {
  //   id: 10,
  //   MenuTitle: "Users",
  //   MenuLink: "/users",
  // },

  {
    id: 12,
    MenuTitle: "Reports",
    MenuLink: "/reports"
  },
  {
    id: 13,
    MenuTitle: "Broadcast Messages",
    MenuLink: "/broadcastMessages"
  },
  // {
  //   id: 13,
  //   MenuTitle: "Manage Notifications",
  //   MenuLink: "/manageNotifications",
  // },
  // {
  //   id: 16,
  //   MenuTitle: "Email Templates",
  //   MenuLink: "/emailTemplates",
  // },
  // {
  //   id: 17,
  //   MenuTitle: "Roles & Permissions",
  //   MenuLink: "/roles",
  // },
  {
    id: 18,
    MenuTitle: "Report an Issue",
    MenuLink: "/reportIssue"
  },
  // {
  //   id: 19,
  //   MenuTitle: "Settings",
  //   MenuLink: "/settings",
  // },
  {
    id: 20,
    MenuTitle: "Help",
    MenuLink: "/help"
  }
]
export const MENU_DATA_USER_SU = [
  {
    id: 1,
    MenuTitle: "Dashboard",
    MenuLink: "/sa/dashboard"
  },
  {
    id: 11,
    MenuTitle: "Help",
    MenuLink: "/sa/help"
  },
  {
    id: 7,
    MenuTitle: "Broadcast Messages",
    MenuLink: "/sa/broadcastMessages"
  }

]

export const Denied_Msg = `You don't have permission to perform this action.`
