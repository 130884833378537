import { Col, Form, InputGroup } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import { Controller } from "react-hook-form"
import { IconExternalLink, IconEye, IconEyeOff } from "@tabler/icons-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import SelectBox from "../SelectBox"
import "react-datepicker/dist/react-datepicker.css"

export const FormField = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => (
  <Form.Group as={Col} md={size}>
    <Form.Label className={`${required ? "required" : ""}`}>
      {label}{" "}
      <Form.Text className="form-label-description" muted>
        {hint}
      </Form.Text>
    </Form.Label>
    <Form.Control
      type={type}
      isInvalid={error ? true : false}
      placeholder={placeholder}
      {...register(name)}
      autoFocus={autoFocus}
      {...props}
    />
    {downloadLink && (
      <Form.Text as="div" className="text-truncate text-break text-end">
        {downloadLink}
      </Form.Text>
    )}
    <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
  </Form.Group>
)
export const FormFieldPassword = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(true)
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <InputGroup className={`input-group-flat ${error ? "is-invalid" : ""}`}>
        <Form.Control
          type={`${isShow ? "password" : "text"}`}
          isInvalid={error ? true : false}
          placeholder={placeholder}
          {...register(name)}
          autoFocus={autoFocus}
          {...props}
        />
        <InputGroup.Text as="span" className={`${error && "border-danger"}`}>
          <Link
            to={""}
            tabIndex="-1"
            className={`${error && "text-danger"}`}
            onClick={() => setIsShow(!isShow)}
          >
            {isShow ? <IconEye stroke={1.5} size={20} /> : <IconEyeOff stroke={1.5} size={20} />}
          </Link>
        </InputGroup.Text>
      </InputGroup>
      {downloadLink && (
        <Form.Text as="div" className="text-truncate text-break text-end">
          {downloadLink}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldCheck = ({
  size = "6",
  label,
  name,
  type = "radio",
  options,
  register,
  error = "",
  inline = true,
  required,
  className = ""
}) => {
  return (
    <Form.Group as={Col} md={size} className={className}>
      <Form.Label className={`${required && "required"}`}>{label}</Form.Label>
      {options.map((option) => (
        <Form.Check
          inline={inline}
          key={option.value || option.label}
          type={type}
          className={`mt-2 ${error ? "is-invalid" : ""}`}
        >
          <Form.Check.Input
            type={type}
            {...register(name)}
            value={option.value}
            isInvalid={error ? true : false}
            id={option.value}
          />
          <Form.Check.Label htmlFor={option.value}>
            {option.label}{" "}
            {option.document && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_UPLOAD_BASE_URL}${option.document}`}
                className="ms-2 d-inline-block text-truncate text-break position-absolute"
                style={{ maxWidth: "calc(100% - 50%)" }}
              >
                <IconExternalLink className="icon me-1" />
                {option.document_name}
              </a>
            )}
          </Form.Check.Label>
        </Form.Check>
      ))}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldSelect = ({
  size = "6",
  label,
  name,
  placeholder = "Select",
  control,
  options = [],
  error = "",
  isClearable = false,
  isSearchable = false,
  required,
  ...props
}) => {
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectBox
            {...field}
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={options}
            className={`form-control p-0 border-0 ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            {...props}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldDatePicker = ({
  label,
  name,
  control,
  error = "",
  placeholder = "MM-DD-YYYY",
  size = "6",
  hint = "",
  autoFocus,
  required,
  ...props
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())

  const filterDate = (date) => {
    // Get the month and year of the date to check
    const dateMonth = date.getMonth()

    // Disable dates from the previous and next months
    return dateMonth === currentMonth
  }

  const handleMonthChange = (newDate) => {
    // Update the current month when the month changes
    setCurrentMonth(newDate.getMonth())
  }
  const fieldName = name

  const preventTyping = (e) => {
    e.preventDefault()
  }

  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value}
            onChangeRaw={preventTyping}
            // onKeyDown={allowOnlyNumeric}
            showMonthDropdown
            showYearDropdown
            filterDate={filterDate}
            dropdownMode="select"
            onChange={(date) => field.onChange(date)}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="MM-dd-yyyy"
            showDisabledMonthNavigation
            fixedHeight
            onMonthChange={handleMonthChange}
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  )
}

export const FormFieldDatePickers = ({
  label,
  name,
  control,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  maxDate = false,
  ...props
}) => {
  const fieldName = name
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value ? moment(field.value, "DD-MMM-YYYY").toDate() : null}
            onChange={(date) => {
              const formattedDate = moment(date).format("DD-MMM-YYYY")
              field.onChange(formattedDate)
            }}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="dd-MMM-yyyy"
            fixedHeight
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  )
}
